<template>
<div class="body fill-height">
  <v-container fluid >
  

    
    <v-container class="d-flex mx-0 mb-3" >
        <div class="my-auto ms-0 ">
          <v-img max-height="100" max-width="80" src="../assets/bjs logo png 1.png"></v-img>
        </div>
        <div class="ms-0 my-auto">
          <v-img max-height="100" max-width="200" src="../assets/logobjsbaru.png"></v-img>
        </div>
    </v-container>

    <v-card class="bgblue card" style="padding:30px;" elevation="0" >

    
      
      <h1 >Detail Dokumen</h1>
      <div class="d-flex " style="margin-top: 20px;">
   
        <div style="cursor: pointer;" @click="print()">
        
          <vue-pdf-embed   style="max-width: 600px;" :source="detailData.docurl" />
        </div>

    <div style="margin-left:50px ;max-width: 200px; text-align: left;">

        <h2>Kode Dokumen</h2>
        <p>{{ detailData.kode }}</p>

        <h2>Tanggal</h2>
        <p>{{ detailData.tgl }}</p>

        <h2>Perihal</h2>
        <p>{{ detailData.perihal }}</p>

        <h2>Lampiran</h2>
        <p>{{ detailData.lampiran }}</p>

        <h2>Link</h2>
        <p>{{ detailData.link }}</p>

        <v-col cols="3" class="pa-0 my-auto">
          <v-btn v-if="isAdmin && isDocAvailable" @click="print" depressed color="success">
            Print
          </v-btn>
        </v-col>
        
    </div>

    <div style="margin-left:50px;text-align: left;">
        <h2>Nomor Rak</h2>
        <p>{{ detailData.rak }}</p>

        <h2>Nomor Baris</h2>
        <p>{{ detailData.baris }}</p>

        <h2>Nomor Kolom</h2>
        <p>{{ detailData.kolom }}</p>

        <h2>Nomor Kardus </h2>
        <p>{{ detailData.kardus }}</p>

        <h2>Nomor Ordner </h2>
        <p>{{ detailData.bantek }}</p>

      
    <v-row >
    

    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-col cols="3" class="my-auto">
        <v-btn
         v-if="isAdmin"
          color="success"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Edit
        </v-btn>
        </v-col>

      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Edit Document</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form class="d-flex">

<div>
  <v-text-field
      v-model="detailData.kode"

      label="Kode Dokumen"

    ></v-text-field>
    <v-text-field
    v-model="detailData.tgl"
      label="Tanggal"
    
    ></v-text-field>

  

    <v-text-field
    v-model="detailData.perihal"
      label="Perihal"

    ></v-text-field>

    <v-text-field
    v-model="detailData.lampiran"
      label="Lampiran"   
 
    ></v-text-field>


    <v-text-field
    v-model="detailData.link"
      label="Link"
    
    ></v-text-field>


</div>

<div style="margin-left:50px;">


  <v-text-field
    v-model="detailData.rak"
      label="Nomor rak"
    ></v-text-field>

    <v-text-field
    v-model="detailData.baris"
      label="Nomor Baris"
    ></v-text-field>

    <v-text-field
    v-model="detailData.kolom"
      label="Nomor Kolom"
    ></v-text-field>

 

    <v-text-field
    v-model="detailData.kardus"
      label="Nomor Kardus"
    ></v-text-field>

    <v-text-field
    v-model="detailData.bantek"
      label="Nomor Ordner"
    ></v-text-field>


</div>
   
  </form>
          </v-container>
        
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="updateData"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog2"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-col cols="12" class="my-auto">
        <v-btn
         v-if="isAdmin && isDocAvailable"
          color="success"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Lanjut ke Proses Peminjaman
        </v-btn>
        </v-col>

      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Pengisian Data Peminjam</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <form class="d-flex">

              <div>
                <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.tglpeminjaman"
                  label="Tgl Peminjaman"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.namapeminjam"
                  label="Nama Peminjam"
                ></v-text-field>
              </v-col>


              </div>

              <div>

                <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-text-field
                  v-model="editedItem.penerima"
                  label="Penerima"
                ></v-text-field>
              </v-col>
          
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
              <v-select
                 @change="selectPeminjam"
                  v-model="key"
                  :items="listPeminjam"
                  label="Peminjam"
                ></v-select>
              </v-col>

              </div>


          
   
  </form>
          </v-container>
        
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog2 = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="inputPinjaman"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
    </div>

    <div style="margin-left:50px;text-align: left;">
        <h2>Tanggal Peminjaman</h2>
        <p>{{detailData.tglpeminjaman  }}</p>

        <h2>Nama Peminjam</h2>
        <p>{{ detailData.peminjam }}</p>

        <h2>Penerima</h2>
        <p>{{ detailData.penerima }}</p>

        <h2>Status</h2>
        <p>{{ detailData.status }}</p>
    </div>

  </div>
   

    </v-card>
    
  </v-container>

</div>


</template>

<script>
import axios from 'axios';

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'
import Swal from 'sweetalert2'

export default {
  name: 'detail-page',
 

  data () {
    return {
        isAdmin:false,
        isSpv: false,
        isPeminjam:false,
        isDocAvailable:false,
        dialog: false,
        dialog2: false,
        detailData : {},
        source1: 'https://res.cloudinary.com/dzx99abz5/image/upload/v1671531556/20221219110326_rnqaic.pdf',
        pdfsrc: null,
        editedItem: {
        tglpeminjaman: '',
        idpeminjam:1,
        namapeminjam: "",
        penerima: "",
        status:"",
        },
        listPeminjam:[],
        listIdpeminjam:[],
                  
}},
components: {
    VuePdfEmbed, 
  },

methods : {

  navigate() {

    if(this.isAdmin && this.isDocAvailable){

    let docid = this.$route.params.id
    const routeData = this.$router.resolve('/pdf/'+ docid)

      window.open(routeData.href, '_blank')
    }
    },


    selectPeminjam(){
        for (let i = 0; i <   this.listPeminjam.length; i++) {

          if(this.listPeminjam[i].name == this.key){
            this.editItem.idpeminjam = this.listIdpeminjam[i]
          }
        }
        

      },


    getPeminjam(){

      axios.get('https://jamkrindo.herokuapp.com/profile').then(response => {
      console.log(response)


            for (let i = 0; i <   response.data.data.length; i++) {

                var item = response.data.data[i];
                if(item.role == "peminjam"){
                  this.listPeminjam.push(item.name)
                  this.listIdpeminjam.push(item.id)
                }
              }


      }).catch(function(error) {
          console.log(error)

      })
      },
  

  
  getData(){

       let docid = this.$route.params.id
      axios.get('https://jamkrindo.herokuapp.com/document/' + docid).then(response => {
                console.log(response)
               
                  var item = response.data.data[0];
                  
                  this.detailData = {
                  "id" : item.id,  
                  "kode": item.kode,
                  "tgl": item.date,
                  "perihal": item.perihal,
                  "lampiran": item.lampiran,
                  "link": item.link,
                  "docurl":item.docurl,
                  "rak": item.norak,
                  "baris": item.nobaris,
                  "kolom": item.nokolom,
                  "kardus": item.nokardus,
                  "bantek": item.nobantek,
                  "tglpeminjaman": item.tglpeminjaman,
                  "peminjam": item.namapeminjam,
                  "penerima": item.penerima,
                  "status": item.status,
                  "cabang":item.cabang,
                  "tipe":item.tipe,
                  "npwp":item.npwp,
                  "tglrekening":item.tglrekening,
               }


               if(item.status == "available"){
                this.isDocAvailable = true
               }else{
                this.isDocAvailable = false
               }

              


                console.log(this.detailData);

            }).catch(function(error) {
                console.log(error)

            })
       
    },
    inputPinjaman(){
      let docid = this.$route.params.id

      this.dialog2 = false

      axios.post('https://jamkrindo.herokuapp.com/peminjaman', {

        tglpeminjaman: this.editedItem.tglpeminjaman,
        idpeminjam: this.editedItem.idpeminjam,
        namapeminjam: this.editedItem.namapeminjam,
        penerima: this.editedItem.penerima,
        status: "not available",
        iddocument: docid,
        kode:this.detailData.kode
        }).then(response => {
        console.log(response)

        // var item = response.data.data[0];
    
        this.detailData.status = "not available"
        this.updateData()
        // this.navigate()
        this.print()

    
      })

    },
    updateData(){
      this.dialog = false
      
       let a = this

            axios.put('https://jamkrindo.herokuapp.com/document', {

              id : a.detailData.id,  
                  kode: a.detailData.kode,
                  date: a.detailData.tgl,
                  perihal: a.detailData.perihal,
                  lampiran: a.detailData.lampiran,
                  link: a.detailData.link,
                  docurl:a.detailData.docurl,
                  norak: a.detailData.rak,
                  nobaris: a.detailData.baris,
                  nokolom: a.detailData.kolom,
                  nokardus: a.detailData.kardus,
                  nobantek: a.detailData.bantek,
                  tglpeminjaman: a.detailData.tglpeminjaman,
                  namapeminjam: a.detailData.peminjam,
                  penerima: a.detailData.penerima,
                  status: a.detailData.status,
                  cabang:a.detailData.cabang,
                  tipe:a.detailData.tipe,
                  npwp:a.detailData.npwp,
                  tglrekening:a.detailData.tglrekening
              
             
            }).then(response => {

              console.log(response)
                Swal.fire({
            icon: 'success',
            text:  "Success"
          })
          
            a.getData()
  
      
            }).catch(function(error) {
                console.log(error)
                Swal.fire({
            icon: 'error',
            text:  error
          })

            })

    },
    print(){
      window.open(this.detailData.docurl, '_blank').focus();
    },


    logout(){
      localStorage.clear()
      this.$router.push("/");
    }



},
created(){
  var userid = localStorage.getItem("userid")
    if(userid == null){
      this.logout()
    }

    var role = localStorage.getItem("userRole")

      if(role == "admin"){
        this.isAdmin = true
        this.items = this.itemsAdmin
      }

      if(role == "supervisor"){
        this.isSpv = true
        this.items = this.itemsSpv
      }

      if(role == "peminjam"){
        this.isPeminjam = true
        this.items = this.itemsPeminjam
      }

        if(role == "bod"){
        this.$router.push("/ob2");
      }

    this.getData()
    this.getPeminjam()

 },

}
</script>

<style scoped>
.body {
    padding-left: 50px;
    padding-right: 50px;
    background-image: url(../assets/background.png);
    background-size: cover;
}

.bgblue{
  background: rgba(86, 207, 255, 0.7);
  border-radius: 15px;
  
}

.background{
  background-image: url(../assets/background.png);
  background-size: cover;
  
}

.bgnone{
  background-color: transparent;
}


h1{
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 45px;
  text-align: center;
}

h2{
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
}

li {
  display: inline;
  padding: 20px;
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #34758E;

}
</style>