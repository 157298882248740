<template>

    <div class="d-flex background fill-height">
    
      
      <v-navigation-drawer
          style="background-color: #039BE5;min-width: 250px;height: auto; min-height: 790px;text-align: left;"
            class=" accent-4"
            dark
            permanent
          >
         
            <v-list>
              <div class="pa-2">
              
                <v-btn v-on:click="menu" block>
                  Menu
                </v-btn>
              </div>
              <v-list-item
                style="color: white;"
                v-for="item in items"
                :key="item.title"
                link
                v-on:click="navigate(item.route)"
              >
            
              <v-list-item-icon>
              <v-icon  >{{ item.icon }}</v-icon>
            </v-list-item-icon>
      
            <v-list-item-content>
              <v-list-item-title  >{{ item.title }}</v-list-item-title>
            </v-list-item-content>
            
              </v-list-item>
            </v-list>
    
    
            <template v-slot:append>
              <div class="pa-2">
                <v-btn block>
                  {{ moment().format('ddd, D MMMM YYYY') }}
                </v-btn>
              </div>

              <div class="px-2">
                <v-btn block>
                  {{ moment().format('LT') }}
                </v-btn>
              </div>

              <div class="pa-2">
                <v-btn v-on:click="logout"  block>
                  Logout
                </v-btn>
              </div>
            </template>
          </v-navigation-drawer>
    
    
          <v-card class="bgblue carduser" >
            <v-container class="d-flex mx-0 mb-3" >

              <v-row>
                <div class="my-auto ms-0 ">
                <v-img max-height="100" max-width="80" src="../assets/bjs logo png 1.png"></v-img>
                </div>
                <div class="ms-0 my-auto">
                <v-img max-height="100" max-width="200" src="../assets/logobjsbaru.png"></v-img>
              </div>

              <v-text-field
                  style="margin-top:40px; margin-left:40px;padding:20px; max-width: 40%;"
                  v-model="search"
                  class="rounded ms-auto"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  @input="onsearch"
                  ></v-text-field>


              </v-row>
           
            </v-container>

   
    
        <v-data-table
          :headers="headers"
          :items="desserts"
          :search="search"
          sort-by="date"
          class="elevation-1 bgnone justify-center"
            >
    <template v-slot:top >
    
      <v-toolbar style="background-color:transparent" 
        flat
      >
        <v-col class="text-center">
        <v-toolbar-title class="fonthead">Kegiatan</v-toolbar-title>
        </v-col>
        
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="isAdmin || isSpv"
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Tulis Kegiatan
            </v-btn>
          </template>
          <v-card>
    
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
    
            <v-card-text>
              <v-container>
                <v-row>
                
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                  >
                    <v-text-field
                      v-model="editedItem.kegiatan"
                      label="Deskripsi Kegiatan"
                    ></v-text-field>
                  </v-col>
              
              
                </v-row>
              </v-container>
            </v-card-text>
    
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Cancel
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      <v-text>No data</v-text>
    </template>
    </v-data-table>
    
          </v-card>
          
    </div>
    
    
    </template>
        
    <script>
        import axios from 'axios';
        import Swal from 'sweetalert2'
        import moment from 'moment'
    
        export default {
          name: 'task-page',
          data: () => ({
          moment:moment,
          search: '',
          isAdmin:false,
          isSpv: false,
          isPeminjam:false,
          isBod:false,
          dialog: false,
          dialogDelete: false,
          headers: [
            {
              text: 'Tanggal ',
              align: 'start',
              sortable: false,
              value: 'date',
            },
            { text: 'Deskripsi Kegiatan', value: 'kegiatan' },
           
          
          ],

          headerBod: [
            {
              text: 'Tanggal ',
              align: 'start',
              sortable: false,
              value: 'date',
            },
            { text: 'Username', value: 'username' },
            { text: 'Role', value: 'role' },
            { text: 'Deskripsi Kegiatan', value: 'kegiatan' },
           
          
          ],
          itemstatus: ['available', 'not available'],
          items: [],
          itemsAdmin: [
            { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
            { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
            { title: 'Users', icon: 'mdi-account',route: '/users'  },
            { title: 'Input', icon: 'mdi-gavel',route: '/input'  },
            { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' },
            { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
          ],
          itemsSpv: [
            { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
            { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
            { title: 'Users', icon: 'mdi-account',route: '/users'  },
            { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
          ],
          itemsPeminjam: [
            { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
            { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
            { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' }
          ],
          itemsBod: [
              { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
              { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
            ],
          desserts: [],
          editedIndex: false,
          editedId :1,
          editedItem: {
            date: '',
            nama: "",
            iduser: "",
            kegiatan:"",
          },
          defaultItem: {
            date: '',
            nama: "",
            iduser: "",
            kegiatan: "",
          },
        }),
    
        computed: {
          formTitle () {
            return "Tambah Kegiatan"
          },
        },
    
        watch: {
          dialog (val) {
            val || this.close()
          },
          dialogDelete (val) {
            val || this.closeDelete()
          },
        },
    
    
        methods: {
          navigate(link) {
          this.$router.push(link);
        },
    
          deleteItem (item) {
            axios.delete('https://jamkrindo.herokuapp.com/peminjaman/' + item.id).then(response => {
                    console.log(response)
                   this.getData();
                   Swal.fire({
                        icon: 'success',
                        text:  "data berhasil dihapus"
                      })
    
                }).catch(function(error) {
                    console.log(error)
                    Swal.fire({
                        icon: 'error',
                        text:  error
                      })
    
                })
          },
    
          deleteItemConfirm () {
        
            this.closeDelete()
          },
    
          close () {
            this.dialog = false
            
          },
    
          closeDelete () {
            this.dialogDelete = false
            this.getData();
          },
    
          save () {

            var userid = localStorage.getItem("userid")

            var username = localStorage.getItem("username")
            var role = localStorage.getItem("userRole")

            var currentTime = moment().format('ddd, D MMMM YYYY') + " " + moment().format('LT')  
          
              
              axios.post('https://jamkrindo.herokuapp.com/tugas', {
    
                date: currentTime,
                role : role,
                nama: username,
                iduser: userid,
                kegiatan: this.editedItem.kegiatan,
    
              }).then(response => {
                console.log(response)
    
                // var item = response.data.data[0];
                Swal.fire({
                  icon: 'success',
                  text: "Kegiatan berhasil ditambahkan"
                })
    
    
                this.getData()
    
              }).catch(function (error) {
                console.log(error)
                Swal.fire({
                  icon: 'error',
                  text: error
                })
    
              })
            
            this.close()
          },
          getData() {
    
            axios.get('https://jamkrindo.herokuapp.com/tugas').then(response => {
                    console.log(response)
                    this.desserts = [];

                    var userid = localStorage.getItem("userid")
    
                    for (let i = 0; i < response.data.data.length; i++) {
    
                      var item = response.data.data[i];

                      if(this.isBod){
                        this.desserts.push({
                      "id" : item.id,
                      "date": item.date,
                      "username": item.nama,
                      "role" : item.role,
                      "iduser": item.iduser,
                      "kegiatan": item.kegiatan,
                   })
                      }else{
                        if(item.iduser == userid){
                        this.desserts.push({
                      "id" : item.id,
                      "date": item.date,
                      "username": item.nama,
                      "role" : item.role,
                      "iduser": item.iduser,
                      "kegiatan": item.kegiatan,
                   })
                      }
                      }

                
                      
                      }
    
                    console.log(this.desserts);
    
                }).catch(function(error) {
                    console.log(error)
    
                })
          },
          logout(){
            Swal.fire({
              title: "<h3 style='font-family:cambria;'>Terima Kasih Telah Menggunakan BJS SIAP</h3>",
              showCancelButton: true,
              icon:'success',
              confirmButtonColor: '#3085d6',
              confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
              cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.clear()
                  this.$router.push("/");
              }
            })
          },
          menu() {
            this.$router.push("/ob2");
          }
        },
    
        created(){
        var userid = localStorage.getItem("userid")
        if(userid == null){
          this.logout()
        }
        var role = localStorage.getItem("userRole")

        if(role == "admin"){
          this.isAdmin = true
          this.items = this.itemsAdmin
        }

        if(role == "supervisor"){
          this.isSpv = true
          this.items = this.itemsSpv
        }

        if(role == "peminjam"){
          this.$router.push("/ob2");
          this.isPeminjam = true
          this.items = this.itemsPeminjam
        }

        if(role == "bod"){
          this.isBod = true
          this.headers = this.headerBod
          this.items = this.itemsBod
      }
        

        this.getData()
     },
        
      
        }
        </script>
        
    <style scoped>
    .backblue {
          background-color: aqua;
          color: aqua;
        
        }
    
    .background{
      background-image: url(../assets/background.png);
      background-size: cover;
    }
    
    .bgnone {
      background-color: transparent;
    }
    
    .carduser {
      margin-left: 100px;
          margin-right: 100px; 
          height: max-content;
          width: 100%;
          margin: 50px; 
          padding: 50px;
    }
    
    .bgblue {
      background: rgba(86, 207, 255, 0.62);
      border-radius: 15px;
    }
    
    .fonthead{
      font-family: gemunuLibre;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      color: #34758E;
    }
    
    li {
      display: inline;
      padding: 20px;
      font-family: gemunuLibre;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      text-decoration-line: underline;
      color: #34758E;
    
    }
    </style>