<template>
  <div class="body fill-height">
    <v-container class="d-flex justify-center" flat>
      <v-row>
        <v-col class="d-flex justify-center mx-auto my-auto" cols="4">
          <div>
            <v-img height="300" width="250" src="../assets/bjs logo png 1.png">
            </v-img>
          </div>
        </v-col>

        <v-col class="justify-start" cols="8">
          <div>
            <v-card :loading="loading" class="card" max-width="600" max-height="500">
              <template>
                <!-- <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear> -->
              </template>

              <v-row>
              <v-col cols="2"></v-col>
              <v-col cols="10">
              <v-card-title class="d-flex justify-center mt-12 text-center">
                <h2>REGISTER</h2>
              </v-card-title>
              </v-col>
              </v-row>

              <v-form ref="form" v-model="valid" lazy-validation>
                <v-container class="d-flex justify-center">
                  <v-row>
                    <v-col cols="2" class="my-auto">
                      <v-img height="35" width="40" padding="5px" src="../assets/Rectangle 4.png">
                      </v-img>
                    </v-col>

                    <v-col cols="10" class="my-auto pa-0">
                      <v-text-field v-model="username" :rules="emailRules" label="Username" required></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="d-flex justify-center">
                  <v-row>
                    <v-col cols="2" class="my-auto">
                      <v-img height="35" width="40" padding="5px" src="../assets/Rectangle 5.png">
                      </v-img>
                    </v-col>
                    <v-text-field v-model="password" :type="show1 ? 'text' : 'password'" name="input-10-1"
                      label="Password" hint="At least 8 characters" counter
                      @click:append="show1 = !show1"></v-text-field>


                  </v-row>
                </v-container>

                <v-container class="d-flex justify-center">
                  <v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="10" class="mt-6 pa-0 ms-auto">
                      <v-btn block color=#56CFFF class="mr-4" @click="register">
                        Register
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>

                <v-container class="d-flex justify-center">
                  <v-row>
                    <v-col cols="2"></v-col>
                    <v-col cols="10" class="mt-6 pa-0 ms-auto">
                      <v-btn block color="error" class="mr-4" @click="cancel">
                        Cancel
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>



              </v-form>

            </v-card>
          </div>
        </v-col>
      </v-row>


    </v-container>
  </div>
</template>
    
<script>
    // import axios from 'axios';
    // import Swal from 'sweetalert2'
    
    export default {
      name: 'register-page',
      data () {
          return {
            loading: false,
            username : "",
            password: ""
    
          }},
      methods: {
        login() {
    
          //  let a = this
    
          // axios.post('http://localhost:8000/login', {
          //     username: this.username,
          //     password: this.password
          //   })
          //   .then(function (response) {
    
          //     console.log(response);
            
          //     a.$router.push('/home');
          //   }).catch(function (error) {
               
          //     Swal.fire({
          //       icon: 'error',
          //       title: 'Oops...',
          //       text: 'Something went wrong!' + error
          //     })
          //     console.log(error);
          //   });
        
        },

        cancel(){
            this.$router.push('/login')
        },

        register(){

        }
      }
    
    }
    </script>
    
<style scoped>
    
    .body {
      background-image: url(../assets/bgonboard.webp);
      background-repeat: repeat-y ;
      background-size:  cover;
      padding-top: 100px;
      
    }
    
    .card {
      margin: 20px;
      padding: 40px;
      background: rgba(145, 212, 238, 0.73);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    
    }
    
    h2{
      font-family: gemunuLibre;
      font-style: normal;
      font-weight: 700;
      font-size: 40px;
      color: #366660;
    
    }
    .fontbaru {
      font-family: 'Courier New', Courier, monospace;
    }
    
    p{
      font-family: gemunuLibre;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      color: #000000;
    }
    
    </style>