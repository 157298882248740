<template>
<div class="body fill-height">

  <div class="d-flex justify-center">
  <h1>SISTEM INFORMASI ARSIP PERUSAHAAN</h1>
  </div>

    <v-container class="d-flex my-0 justify-center">

        <div class="my-auto ms-0 ">
          <v-img max-height="200" max-width="150" src="../assets/bjs logo png 1.png"></v-img>
        </div>
        <div class="my-auto ms-0 ">
          <v-img max-height="200" max-width="500" src="../assets/logobjsbaru.png"></v-img>
        </div>
    </v-container>

    <v-container
      class="d-flex justify-center mb-6 "
      flat
      tile
    >
    <v-row class="mx-auto justify-center">
    <v-card
  @click="login"
   
    class="my-12 card"
    max-width="374"
  >


    <v-img
      height="250"
      width="250"
      src="../assets/director.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>BOD</p></v-card-title>

  </v-card>


    <v-card
    @click="login"
    class="my-12 card"
    max-width="374"
  >
 
    <v-img
      height="250"
      width="250"
      src="../assets/supervisor.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>SUPERVISOR</p></v-card-title>

  </v-card>

  <v-card
  @click="login"
  
    class="my-12 card"
    max-width="374"
  >


    <v-img
      height="250"
      width="250"
      src="../assets/admin.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>ADMINISTRATOR</p></v-card-title>

  </v-card>

  <v-card
  @click="login"
   
    class="my-12 card"
    max-width="374"
  >


    <v-img
      height="250"
      width="250"
      src="../assets/borrow.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>PEMINJAM</p></v-card-title>

  </v-card>
  </v-row>

    </v-container>

</div>
</template>

<script>
// import axios from 'axios';

export default {
  name: 'onboard-page',
  methods: {
    login() {
      this.$router.push('/login');
    },
    goHome(){
      this.$router.push('/ob2');
    }
  },
  beforeMount() {
    var userid = localStorage.getItem("userid")
    if(userid != null){
      this.goHome()
    }
  }

}
</script>

<style scoped>
.body {
    background-image: url(../assets/bgonboard.webp);
    background-repeat: repeat-y ;
    background-size:  cover;
    padding-top: 40px;
  
}

.card {
  margin: 20px;
  padding: 16px;
  /* background: linear-gradient(180deg, #366660 0%, rgba(35, 48, 46, 0) 100%); */
  background: rgba(86, 207, 255, 0.7);
}

p{
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #262fa1;
}

h1{
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  color: #34758E;
}
</style>