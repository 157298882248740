<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>

</template>


<script>
export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>

<style scoped>
@font-face{
  font-family: gemunuLibre;
  src: url(@/font/GemunuLibre-Regular.ttf);
}

</style>
