<template>

<div class="d-flex background fill-height">

  
  <v-navigation-drawer
      style="background-color: #039BE5;min-width: 250px;height: auto; min-height: 790px;text-align: left;"
        class=" accent-4"
      
       
        dark
        permanent
      >
     
     
        <v-list>
          <div class="pa-2">
          
            <v-btn v-on:click="menuboard" block>
              Menu
            </v-btn>
          </div>

          <v-list-item
            style="color: white;"
            v-for="item in items"
            :key="item.title"
            link
            v-on:click="navigate(item.route)"
          >
        
    
          <v-list-item-icon>
          <v-icon  >{{ item.icon }}</v-icon>
        </v-list-item-icon>
  
        <v-list-item-content>
          <v-list-item-title >{{ 
            item.title 
            }}</v-list-item-title>
        </v-list-item-content>
        
          </v-list-item>
        </v-list>


        <template v-slot:append>
          <div class="pa-2">
            <v-btn block>
              {{ moment().format('ddd, D MMMM YYYY') }}
            </v-btn>
          </div>
          
          <div class="px-2">
            <v-btn block>
              {{ moment().format('LT') }}
            </v-btn>
          </div>

          <div class="pa-2">
            <v-btn v-on:click="logout" block>
              Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>

 

     <v-container>

      <v-card 
      
      class="background bgblue justify-center "
       style="margin-left: 150px; margin-top: 40px; margin-right: 150px; width: auto;  padding: 50px;"
         
      >

      <v-container class="d-flex mx-0 mb-3">
        <div class="my-auto ms-0 ">
          <v-img max-height="100" max-width="80" src="../assets/bjs logo png 1.png"></v-img>
        </div>
        <div class="ms-0 my-auto">
          <v-img max-height="100" max-width="200" src="../assets/logobjsbaru.png"></v-img>
        </div>
      </v-container>

<form class="d-flex " style="padding:40px;">

<div >
<v-text-field
v-model="kode"
label="Kode Dokumen"
></v-text-field>


<v-text-field
v-model="date"
label="Tanggal"
></v-text-field>

<v-text-field
v-model="lampiran"
label="Lampiran"   
></v-text-field>

<v-text-field
v-model="link"
label="Link"
></v-text-field>

<v-text-field
v-model="perihal"
label="Perihal"
></v-text-field>

</div>

<div style="margin-left:50px;">

<v-text-field
v-model="norak"
label="Nomor rak"
></v-text-field>


<v-text-field
v-model="nobaris"
label="Nomor Baris"
></v-text-field>

<v-text-field
v-model="nokolom"
label="Nomor Urut"
></v-text-field>

<v-text-field
v-model="nokardus"
label="Nomor Kardus"
></v-text-field>

<v-text-field
v-model="nobantek"
label="Nomor Ordner"
></v-text-field>





</div>


<div style="margin-left:50px;">

  <v-text-field
v-model="date2"
label="Tanggal Peminjaman"
></v-text-field>

<v-text-field
v-model="namapeminjam"
label="Nama peminjam"
></v-text-field>

<v-text-field
v-model="penerima"
label="Penerima"
></v-text-field>
 
<!-- <v-select
v-model="statusdoc"
    :items="itemstatus"
      abel="Standard"
  ></v-select> -->

<div class="mt-3 text-center">
<label style="background-color: #039BE5; font-size: 18px;padding: 12px; border-radius: 5px; color: white; cursor:pointer;margin-top: 40px;"> Select Document
  <input  type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
</label></div>

</div>

</form>

<v-row class="mt-5">
<v-col class="my-auto ms-8 text-left">
  <v-btn @click="upload" color="success">
    submit
  </v-btn>
  <v-dialog
      v-model="dialog"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
</v-col>

<v-col class="my-auto text-right me-10">
  <v-btn @click="clear" color="error">
    clear
  </v-btn>
</v-col>
</v-row>

      </v-card>
     </v-container>
     


      </div>
      





</template>
    
<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import axios from 'axios';
import Swal from 'sweetalert2'
import moment from 'moment'


export default {

  mixins: [validationMixin],
      name: 'create-page',
   

    validations: {
      baseinput: { required },   
    },

    data: () => ({
      moment:moment,
      isAdmin:false,
      isSpv: false,
      isPeminjam:false,
      kode:"",
      lampiran:"",
      perihal:"",
      link:"",
      norak:"",
      nobaris:"",
      nokolom:"",
      nokardus:"",
      nobantek:"",
      tglpeminjaman:"-",
      namapeminjam:"-",
      penerima:"-",
      statusdoc:"available",
      document:"",
      dialog: false,
      file:"",
      role: [
        'Supervisor',
        'Administrator',
        'Peminjam',
      ],
      checkbox: false,
      items: [],
      itemsAdmin: [
        { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
        { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
        { title: 'Users', icon: 'mdi-account',route: '/users'  },
        { title: 'Input', icon: 'mdi-gavel',route: '/input'  },
        { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' },
        { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
      ],
      itemsSpv: [
        { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
        { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
        { title: 'Users', icon: 'mdi-account',route: '/users'  },
        { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
      ],
      itemsPeminjam: [
        { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
        { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
        { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' }
      ],
      baseerror: "field ini harus di isi",

          date: "",
          date2: "-",
          menu: false,
          modal: false,
          menu2: false,   
          itemstatus: ['available', 'not available'],
    }),

    computed: {
    },

    methods: {
      upload(){


         
        if (this.kode == "" || this.date == "" || this.perihal == ""
            || this.lampiran == "" || this.link == ""|| this.norak == ""
            || this.nobaris == "" || this.nokolom == "" || this.nobantek == ""  ) {

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  'Mohon Lengkapi Semua Data'
            })

            }else{

   
        this.dialog = true
            let formData = new FormData();

        
            formData.append('file', this.file);

  

        let a = this

            axios.post( 'https://jamkrindo.herokuapp.com/upload',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(response){
           a.document = response.data.data.url 
          a.submit();
        })
        .catch(function(){
          a.dialog = false
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  'dokumen tidak valid'
          })
        });

      }
      },


      submit () {

     

        let a = this

        axios.post('https://jamkrindo.herokuapp.com/document', {
          kode:this.kode,
          date:this.date,
          perihal:this.perihal,
          lampiran:this.lampiran,
          link:this.link,
          docurl:this.document,
          norak:this.norak,
          nobaris:this.nobaris,
          nokolom:this.nokolom,
          nokardus:this.nokardus,
          nobantek:this.nobantek,
          tglpeminjaman:this.date2,
          namapeminjam:this.namapeminjam,
          penerima:this.penerima,
          status:"available",
          cabang:"",
          tipe:"",
          npwp:"",
          tglrekening:"",
        
        })
        .then(function (response) {

          console.log(response);

          Swal.fire({
            icon: 'success',
            title: 'Data berhasil dibuat',
            showConfirmButton: false,
            timer: 1500
          })

          a.clear();
          a.dialog = false


        }).catch(function (error) {
          a.dialog = false
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  error
          })
          console.log(error);
        });
      
      },
      navigate(link) {
      this.$router.push(link);
    },
      clear () {
        this.kode ="";
       
        this.lampiran="";
        this.perihal="";
        this.link="";
        this.norak="";
        this.nobaris="";
        this.nokolom="";
        this.nokardus="";
        this.nobantek="";
        this.namapeminjam="";
        this.penerima="";
        this.statusdoc="available";
        this.document="";
        this.file="";
        this.date ="";
        this.date2 ="";

      },
      handleFileUpload(){
        this.file = this.$refs.file.files[0];
      },
      logout(){
        Swal.fire({
          title: "<h3 style='font-family:cambria;'>Terima Kasih Telah Menggunakan BJS SIAP</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })
      },
      menuboard() {
        this.$router.push("/ob2");
      }
    },
    beforeMount(){
      var userid = localStorage.getItem("userid")
        if(userid == null){
          this.$router.push("/");
        }
        var role = localStorage.getItem("userRole")

        if(role == "admin"){
          this.isAdmin = true
          this.items = this.itemsAdmin
        }

        if(role == "supervisor"){
          this.$router.push("/ob2");
          this.isSpv = true
          this.items = this.itemsSpv
        }

        if(role == "peminjam"){
          this.$router.push("/ob2");
          this.isPeminjam = true
          this.items = this.itemsPeminjam
        }

        if(role == "bod"){
        this.$router.push("/ob2");
      }
    }
  }
     
    
    </script>
    
<style scoped>
.backblue {
      background-color: aqua;
      color: aqua;
    }

.background{
  background-image: url(../assets/background.png);
  background-size: cover;
}

.bgblue{
  background: rgba(86, 207, 255, 0.62);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

li {
  display: inline;
  padding: 20px;
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #34758E;

}
</style>