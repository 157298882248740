<template>
	<div >
	
		<PSPDFKitContainer :pdfFile="pdfFile" @loaded="handleLoaded" />
	</div>
</template>

<script>
import PSPDFKitContainer from '../views/PSPDFKitContainer';
import axios from 'axios';

export default {
    name: 'edit-pdf',
	data() {
		return {
			pdfFile: this.pdfFile || '/example.pdf',
		};
	},
	/**
	 * Render the `PSPDFKitContainer` component.
	 */
	components: {
		PSPDFKitContainer,
	},
	/**
	 * Our component has two methods — one to check when the document is loaded, and the other to open the document.
	 */
	methods: {
		handleLoaded(instance) {
			console.log('PSPDFKit has loaded: ', instance);
			// Do something.
		},

		openDocument() {
			// To access the Vue instance data properties, use `this` keyword.
			if (this.pdfFile) {
				window.URL.revokeObjectURL(this.pdfFile);
			}
			this.pdfFile = window.URL.createObjectURL(
				event.target.files[0],
			);
		},
        getData(){

let docid = this.$route.params.id
axios.get('https://jamkrindo.herokuapp.com/document/' + docid).then(response => {
         console.log(response)
    
           var item = response.data.data[0];
           this.pdfFile = item.docurl;
           
         console.log(this.detailData);

     }).catch(function(error) {
         console.log(error)

     })

},
logout(){
      localStorage.clear()
      this.$router.push("/");
    }
},
created(){
  var userid = localStorage.getItem("userid")
    if(userid == null){
      this.logout()
    }

	var role = localStorage.getItem("userRole")

		if(role == "admin"){
		this.isAdmin = true
		this.items = this.itemsAdmin
		}

		if(role == "supervisor"){
			this.$router.push("/ob2");
		}

		if(role == "peminjam"){
			this.$router.push("/ob2");
		}

		if(role == "bod"){
        this.$router.push("/ob2");
      }





    this.getData()

 },

};
</script>

<style>
#app {
	text-align: center;
	color: #2c3e50;
}

body {
	margin: 0;
}

input[type='file'] {
	display: none;
}

.custom-file-upload {
	border: 1px solid #ccc;
	border-radius: 4px;
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
	background: #4a8fed;
	padding: 10px;
	color: #fff;
	font: inherit;
	font-size: 16px;
	font-weight: bold;
}
</style>