<template>

<div class="d-flex background fill-height">
  
  <v-navigation-drawer
      style="background-color: #039BE5;min-width: 250px;height: auto; min-height: 790px;text-align: left;"
        class=" accent-4"
      
       
        dark
        permanent
      >
     
        <v-list>
          <div class="pa-2">
          
            <v-btn v-on:click="menu" block>
              Menu
            </v-btn>
          </div>

          <v-list-item
            style="color: white;"
            v-for="item in items"
            :key="item.title"
            link
            v-on:click="navigate(item.route)"
          >
        
            <v-list-item-icon>
              <v-icon  >{{
              item.icon }}</v-icon>
            </v-list-item-icon>
  
            <v-list-item-content>
              <v-list-item-title >{{ item.title }}</v-list-item-title>
            </v-list-item-content>
        
          </v-list-item>
        </v-list>


        <template v-slot:append>

          <div class="pa-2">
            <v-btn block>
              {{ moment().format('ddd, D MMMM YYYY') }}
            </v-btn>
          </div>

          <div class="px-2">
            <v-btn block>
              {{ moment().format('LT') }}
            </v-btn>
          </div>

          <div class="pa-2">
            <v-btn v-on:click="logout" block>
              Logout
            </v-btn>
          </div>

        </template>
      </v-navigation-drawer>




 

    
      <horizontal-scroll>
    <v-card class="bgblue" style="margin: 50px; padding: 50px; "  >

      <v-container class="d-flex mx-0 mb-3" fluid>
      <v-row>
      <div class="my-auto ms-0 ">
        <v-img max-height="100" max-width="80" src="../assets/bjs logo png 1.png"></v-img>
      </div>
      <div class="ms-0 my-auto">
        <v-img max-height="100" max-width="200" src="../assets/logobjsbaru.png"></v-img>
      </div>
 
        <v-text-field
        style="margin-top:40px; margin-left:40px;padding:20px; max-width: 40%;"
        v-model="search"
        class="rounded ms-auto"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        @input="onsearch"
        ></v-text-field>

        <v-text  style="margin-top:45px; padding:20px; max-width: 40%; font-size: 18px; font-weight: bold; ">
          Total: {{ total }}
        </v-text>

      </v-row>
      </v-container>

      

    <v-card-title >
      <v-chip
      class="ma-2"
      color="orange"
     

    >
    Dokumen
    </v-chip>
    
      <v-spacer></v-spacer>
      <v-chip
      class="ma-2"
      color="light-green"
    
     
    >
    Penyimpanan Fisik
    </v-chip>
  
 
     

  

    </v-card-title>

 

   
    <v-data-table
      ref="myTable"
      class="bgnone"
      :headers="headers"
      :items="desserts"
      :search="search"
    >
   
    <template v-slot:[`item.link`]="{ item }">
    <a v-on:click="navigate('/detail/' + item.id)">
      {{ item.link }}
    </a>
        
    </template>
    

    <template v-slot:[`item.kode`]="{ item }">
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-text
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ item.kode }}
        </v-text>
      </template>
      <span>
          PKS - Perjanjian Kerja Sama (Bisnis).<br>

          SPK - Surat Perintah Kerja (Bisnis).<br>

          SK - Surat Keluar (Bisnis).<br>

          SM - Surat Masuk (Bisnis).<br>

          MEM - Memorandum (Keuangan).<br>

          MCR - Memo Customer Receipt (Keuangan).<br>

          TGH - Tagihan (Keuangan).<br>

          P23 - Bukti Potong Pajak PPH23 (Keuangan).<br>

          P21 - PPH 21 (Keuangan).<br>

          FP - Faktur Pajak (Keuangan).<br>

          FP - Faktur Pajak (Keuangan).<br>

          BK - Bank Keluar (Keuangan).<br>

          PTC - Petty Cash (Keuangan).<br>

          RK - Rekening Koran (Keuangan).<br>

          MMB - Memo MB (Keuangan).<br>

          KP - Kontrak Personal (SDM).<br>

          NTL - Notulen.
</span>
    </v-tooltip>
        
    </template>
    

  </v-data-table>




  </v-card>


</horizontal-scroll>



  

</div>



</template>

<script>
 import axios from 'axios';
 import HorizontalScroll from 'vue-horizontal-scroll'
 import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
 import Swal from 'sweetalert2'
 import moment from 'moment'


export default {
  name: 'home-page',
  data () {
      return {
        moment:moment,
        search: '',
        isAdmin:false,
        isSpv: false,
        isPeminjam:false,
        headers: [
          {
            text: 'Kode Dokumen',
            align: 'center',
            sortable: false,
            value: 'kode',
            class: "orange",
     
          },
          { text: 'Tanggal', value: 'tgl',   class: "orange", align: "center" },
          { text: 'Perihal', value: 'perihal' ,   class: "orange", align: "center", width:200 },
          { text: 'Lampiran', value: 'lampiran',   class: "orange" , align: "center" },
          { text: 'Link', value: 'link' ,   class: "red", align: "center" },
          { text: 'No Rak', value: 'rak',   class: "light-green", align: "center"  },
          { text: 'No Baris', value: 'baris',   class: "light-green", align: "center"  },
          { text: 'No Urut', value: 'kolom' ,   class: "light-green", align: "center" },
          { text: 'No Kardus', value: 'kardus',   class: "light-green", align: "center"  },
          { text: 'No Ordner', value: 'bantek',   class: "light-green" , align: "center" },
   

        
        ],
        total:0,
        desserts: [],
        items: [],
        itemsAdmin: [
          { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
          { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
          { title: 'Users', icon: 'mdi-account',route: '/users'  },
          { title: 'Input', icon: 'mdi-gavel',route: '/input'  },
          { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' },
          { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
        ],
        itemsSpv: [
          { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
          { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
          { title: 'Users', icon: 'mdi-account',route: '/users'  },
          { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
        ],
        itemsPeminjam: [
          { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
          { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
          { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' }
        ],
      }
  },
  methods : {

    navigate(link) {
      this.$router.push(link);
    },
    getData(){
      axios.get('https://jamkrindo.herokuapp.com/document').then(response => {
                console.log(response)
                this.desserts = [];
                this.total = response.data.data.length; 

                for (let i = 0; i <   response.data.data.length; i++) {

                  var item = response.data.data[i];

                  if(item.kode != ""){
                  
                  this.desserts.push({
                  "id" : item.id,  
                  "kode": item.kode,
                  "tgl": item.date,
                  "perihal": item.perihal,
                  "lampiran": item.lampiran,
                  "link": item.link,
                  "rak": item.norak,
                  "baris": item.nobaris,
                  "kolom": item.nokolom,
                  "kardus": item.nokardus,
                  "bantek": item.nobantek,
                  "tglpeminjaman": item.tglpeminjaman,
                  "namapeminjam": item.namapeminjam,
                  "penerima": item.penerima,
                  "status": item.status,
                  "cabang":item.cabang
               }
               )
                  }
                }

                console.log(this.desserts);

            }).catch(function(error) {
                console.log(error)

            })
       
    },
    logout(){
      Swal.fire({
          title: "<h3 style='font-family:cambria;'>Terima Kasih Telah Menggunakan BJS SIAP</h3>",
          showCancelButton: true,
          icon: 'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })
     
    },
    menu() {
      this.$router.push("/ob2");
    },
    onsearch(){
      this.total = this.$refs.myTable.selectableItems.length
    }

  },
  components: {
        HorizontalScroll
    },


  created(){
    var userid = localStorage.getItem("userid")
    if(userid == null){
      this.$router.push("/");
    }

    var role = localStorage.getItem("userRole")

      if(role == "admin"){
        this.isAdmin = true
        this.items = this.itemsAdmin
      }

      if(role == "supervisor"){
        this.isSpv = true
        this.items = this.itemsSpv
      }

      if(role == "peminjam"){
        this.isPeminjam = true
        this.items = this.itemsPeminjam
      }

      if(role == "bod"){
        this.$router.push("/ob2");
      }


    this.getData()
 },


}
</script>

<style scoped>
.backblue {
  background-color: aqua;
  color: aqua;

}

.background{
  background-image: url(../assets/background.png);
  background-size: cover;
  
}

.bgblue{
  background: rgba(86, 207, 255, 0.7);
  border-radius: 15px;
}

.bgnone{
  background-color: transparent;
}

li{
  display: inline;
  padding: 20px;
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #34758E;
  
}
</style>