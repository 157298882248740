<template>

<div class="d-flex background fill-height">

  
  <v-navigation-drawer
      style="background-color: #039BE5;min-width: 250px;height: auto; min-height: 790px;text-align: left;"
        class=" accent-4"
      
       
        dark
        permanent
      >
     
        <v-list>
          <div class="pa-2">
          
            <v-btn v-on:click="menu" block>
              Menu
            </v-btn>
          </div>
          <v-list-item
            style="color: white;"
            v-for="item in items"
            :key="item.title"
            link
            v-on:click="navigate(item.route)"
          >
        
          <v-list-item-icon>
          <v-icon  >{{ item.icon }}</v-icon>
        </v-list-item-icon>
  
        <v-list-item-content>
          <v-list-item-title  >{{ item.title }}</v-list-item-title>
        </v-list-item-content>
        
          </v-list-item>
        </v-list>


        <template v-slot:append>
          <div class="pa-2">
            <v-btn block>
              {{ moment().format('ddd, D MMMM YYYY') }}
            </v-btn>
          </div>
          
          <div class="px-2">
            <v-btn block>
              {{ moment().format('LT') }}
            </v-btn>
          </div>

          <div class="pa-2">
            <v-btn v-on:click="logout"  block>
              Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>



      <v-card class="bgblue carduser" >

        <v-container class="d-flex mx-0 mb-3" >
          <v-row>
              <div class="my-auto ms-0 ">
                <v-img max-height="100" max-width="80" src="../assets/bjs logo png 1.png"></v-img>
              </div>
              <div class="ms-0 my-auto">
                <v-img max-height="100" max-width="200" src="../assets/logobjsbaru.png"></v-img>
              </div>

              <v-text-field
                  style="margin-top:40px; margin-left:40px;padding:20px; max-width: 40%;"
                  v-model="search"
                  class="rounded ms-auto"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  @input="onsearch"
                  ></v-text-field>


              </v-row>
        </v-container>

    <v-data-table
    
      :headers="headers"
      :items="desserts"
      :search="search"
      sort-by="calories"
      class="elevation-1 bgnone justify-center"
        >
<template v-slot:top >

  <v-toolbar style="background-color:transparent" 
    flat
  >
    <v-col class="text-center">
    <v-toolbar-title class="fonthead">Peminjaman</v-toolbar-title>
    </v-col>
    
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >

      <v-card>

        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.tglpeminjaman"
                  label="Tgl Peminjaman"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.namapeminjam"
                  label="Nama Peminjam"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
                <v-text-field
                  v-model="editedItem.penerima"
                  label="Penerima"
                ></v-text-field>
              </v-col>
          
              <v-col
                cols="12"
                sm="6"
                md="4"
              >
              <v-select
                 @change="selectPeminjam"
                  v-model="key"
                  :items="listPeminjam"
                  label="Peminjam"
                ></v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>
<template v-slot:[`item.actions`]="{ item }">
 
  <v-icon
    small
    @click="deleteItem(item)"
  >
    mdi-package-down
  </v-icon>
</template>
<template v-slot:[`item.status`]="{ item }">
      <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-text
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          {{ item.status }}
        </v-text>
      </template>
      <span>
          available - (Dokumen Tersedia).<br>

          not available - (Dokumen Lagi dipinjam).<br>
</span>
    </v-tooltip>
        
    </template>

    <template v-slot:[`item.kode`]="{ item }">
    <a v-on:click="navigate('/detail/' + item.iddocument)">
      {{ item.kode }}
    </a>
        
    </template>




<template v-slot:no-data>
  <v-text>No data</v-text>
</template>
</v-data-table>

      </v-card>
      
</div>


</template>
    
<script>
    import axios from 'axios';
    import Swal from 'sweetalert2'
    import moment from 'moment'

    export default {
      name: 'input-page',
      data: () => ({
      moment:moment,
      search:'',
      isAdmin:false,
      isSpv: false,
      isPeminjam:false,
      dialog: false,
      dialogDelete: false,
      listPeminjam:[],
      listIdpeminjam:[],
      detailData : {},
      key: "",
      headers: [
        {
          text: 'Tgl Peminjaman',
          align: 'start',
          sortable: false,
          value: 'tglpeminjaman',
        },
        { text: 'Kode Dokumen', value: 'kode' },
        { text: 'Nama Peminjam', value: 'namapeminjam' },
        { text: 'Admin', value: 'penerima' },
        { text: 'Status', value: 'status' },
        { text: 'Pengembalian', value: 'actions', sortable: false },
      ],
      headersPeminjaman: [
        {
          text: 'Tgl Peminjaman',
          align: 'start',
          sortable: false,
          value: 'tglpeminjaman',
        },
        { text: 'Kode Dokumen', value: 'kode' },
        { text: 'Nama Peminjam', value: 'namapeminjam' },
        { text: 'Admin', value: 'penerima' },
        { text: 'Status', value: 'status' },
      ],
      itemstatus: ['available', 'not available'],
      items: [],
      itemsAdmin: [
        { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
        { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
        { title: 'Users', icon: 'mdi-account',route: '/users'  },
        { title: 'Input', icon: 'mdi-gavel',route: '/input'  },
        { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' },
        { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
      ],
      itemsSpv: [
        { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
        { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
        { title: 'Users', icon: 'mdi-account',route: '/users'  },
        { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
      ],
      itemsPeminjam: [
        { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
        { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
        { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' }
      ],
      desserts: [],
      editedIndex: false,
      editedId :1,
      editedItem: {
        tglpeminjaman: '',
        idpeminjam:1,
        namapeminjam: "",
        penerima: "",
        status:"",
      },
      defaultItem: {
        tglpeminjaman: '',
        idpeminjam:1,
        namapeminjam: "",
        penerima: "",
        status: "",
      },
    }),

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    methods: {
      selectPeminjam(){
        for (let i = 0; i <   this.listPeminjam.length; i++) {

          if(this.listPeminjam[i].name == this.key){
            this.editItem.idpeminjam = this.listIdpeminjam[i]
          }
        }
        

      },
      editItem (item) {
        console.log(item)

        this.editedId = item.id
        this.idpeminjam = item.idpeminjam
        this.editedItem.tglpeminjaman = item.tglpeminjaman
        this.editedItem.namapeminjam = item.namapeminjam
        this.editedItem.penerima = item.penerima
        this.editedItem.status = item.status
      
        this.editedIndex = true;
        this.dialog = true
      },

      navigate(link) {
      this.$router.push(link);
    },
    updateDocument(docid){

   
      axios.get('https://jamkrindo.herokuapp.com/document/' + docid).then(response => {
                console.log(response)
               
                  var item = response.data.data[0];
                  
                  this.detailData = {
                  "id" : item.id,  
                  "kode": item.kode,
                  "tgl": item.date,
                  "perihal": item.perihal,
                  "lampiran": item.lampiran,
                  "link": item.link,
                  "docurl":item.docurl,
                  "rak": item.norak,
                  "baris": item.nobaris,
                  "kolom": item.nokolom,
                  "kardus": item.nokardus,
                  "bantek": item.nobantek,
                  "tglpeminjaman": item.tglpeminjaman,
                  "peminjam": item.namapeminjam,
                  "penerima": item.penerima,
                  "status": item.status,
                  "cabang":item.cabang,
                  "tipe":item.tipe,
                  "npwp":item.npwp,
                  "tglrekening":item.tglrekening,
               }

               this.setDocument()


            }).catch(function(error) {
                console.log(error)

                Swal.fire({
          icon: 'error',
          text:  error
          })

            })

    },

    setDocument(){
      let a = this

axios.put('https://jamkrindo.herokuapp.com/document', {

  id : a.detailData.id,  
      kode: a.detailData.kode,
      date: a.detailData.tgl,
      perihal: a.detailData.perihal,
      lampiran: a.detailData.lampiran,
      link: a.detailData.link,
      docurl:a.detailData.docurl,
      norak: a.detailData.rak,
      nobaris: a.detailData.baris,
      nokolom: a.detailData.kolom,
      nokardus: a.detailData.kardus,
      nobantek: a.detailData.bantek,
      tglpeminjaman: a.detailData.tglpeminjaman,
      namapeminjam: a.detailData.peminjam,
      penerima: a.detailData.penerima,
      status: "available",
      cabang:a.detailData.cabang,
      tipe:a.detailData.tipe,
      npwp:a.detailData.npwp,
      tglrekening:a.detailData.tglrekening
  
 
          }).then(response => {
              Swal.fire({
          icon: 'success',
          text:  "Document dikembalikan"
          })

          console.log(response)

          a.getData()

          }).catch(function(error) {
              console.log(error)
              Swal.fire({
          icon: 'error',
          text:  error
          })

          })
    },
     
      deleteItem (item) {
      
        Swal.fire({
          title: 'Apakah Dokumen Akan dikembalikan ?', 
          showCancelButton: true,
          icon:'question',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Iya'
        }).then((result) => {
          if (result.isConfirmed) {
         
          axios.put('https://jamkrindo.herokuapp.com/peminjaman', {

            id:item.id,
            tglpeminjaman: item.tglpeminjaman,
            idpeminjam: item.idpeminjam,
            namapeminjam: item.namapeminjam,
            penerima: item.penerima,
            status: "available",
            iddocument: item.iddocument,
            kode:item.kode
          
          }).then(response => {
                console.log(response)
              
                
                this.updateDocument(item.iddocument)

            }).catch(function(error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    text:  error
                  })

            })
            
          }
        })

   
      },

      deleteItemConfirm () {
    
        this.closeDelete()
      },

      close () {
        this.dialog = false
        
      },
      getPeminjam(){

        axios.get('https://jamkrindo.herokuapp.com/profile').then(response => {
        console.log(response)


              for (let i = 0; i <   response.data.data.length; i++) {

                  var item = response.data.data[i];
                  if(item.role == "peminjam"){
                    this.listPeminjam.push(item.name)
                    this.listIdpeminjam.push(item.id)
                  }
                }


        }).catch(function(error) {
            console.log(error)

        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.getData();
      },

      save () {
        if (this.editedIndex == true) {

          console.log(this.editedItem)

          let a = this

          axios.put('https://jamkrindo.herokuapp.com/peminjaman', {

            id:this.editedId,
            tglpeminjaman: a.editedItem.tglpeminjaman,
            idpeminjam: a.editedItem.idpeminjam,
            namapeminjam: a.editedItem.namapeminjam,
            penerima: a.editedItem.penerima,
            status: a.editedItem.status
           
          }).then(response => {
                console.log(response)
            
                  
                  Swal.fire({
                    icon: 'success',
                    text:  "user berhasil diubah"
                  })
          
                console.log(this.desserts);
                this.getData()

            }).catch(function(error) {
                console.log(error)
                Swal.fire({
                    icon: 'error',
                    text:  error
                  })

            })
            this.editedIndex = false
        } else {
          
          axios.post('https://jamkrindo.herokuapp.com/peminjaman', {

            tglpeminjaman: this.editedItem.tglpeminjaman,
            idpeminjam: this.editedItem.idpeminjam,
            namapeminjam: this.editedItem.namapeminjam,
            penerima: this.editedItem.penerima,
            status: "not available",

          }).then(response => {
            console.log(response)


            // var item = response.data.data[0];
            Swal.fire({
              icon: 'success',
              text: "user berhasil ditambahkan"
            })



            this.getData()

          }).catch(function (error) {
            console.log(error)
            Swal.fire({
              icon: 'error',
              text: error
            })

          })
        }
        this.close()
      },
      getData() {

        axios.get('https://jamkrindo.herokuapp.com/peminjaman').then(response => {
                console.log(response)
                this.desserts = [];

                for (let i = 0; i <   response.data.data.length; i++) {

                  var item = response.data.data[i];

                  if(this.isAdmin){
                    this.desserts.push({
                  "id" : item.id,
                  "idpeminjam": item.idpeminjam,
                  "tglpeminjaman": item.tglpeminjaman,
                  "namapeminjam": item.namapeminjam,
                  "penerima": item.penerima,
                  "status": item.status,
                  "iddocument":item.iddocument,
                  "kode":item.kode
               }
               )
                  }else{
                    var userid = localStorage.getItem("userid")
                    if(userid == item.idpeminjam){
                      this.desserts.push({
                  "id" : item.id,
                  "idpeminjam": item.idpeminjam,
                  "tglpeminjaman": item.tglpeminjaman,
                  "namapeminjam": item.namapeminjam,
                  "penerima": item.penerima,
                  "status": item.status,
                  "iddocument":item.iddocument,
                  "kode":item.kode
               })
                    }
                  }
                  }

                console.log(this.desserts);

            }).catch(function(error) {
                console.log(error)

            })
      },
      logout(){
        Swal.fire({
          title: "<h3 style='font-family:cambria;'>Terima Kasih Telah Menggunakan BJS SIAP</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })
      },
      menu() {
        this.$router.push("/ob2");
      }
    },

    created(){
      var userid = localStorage.getItem("userid")
        if(userid == null){
          this.$router.push("/");
        }
        var role = localStorage.getItem("userRole")

        if(role == "admin"){
          this.isAdmin = true
          this.items = this.itemsAdmin
        }

        if(role == "supervisor"){
          this.isSpv = true
          this.items = this.itemsSpv
        }

        if(role == "peminjam"){
          this.headers = this.headersPeminjaman
          this.isPeminjam = true
          this.items = this.itemsPeminjam
        }

        if(role == "bod"){
        this.$router.push("/ob2");
      }
    this.getData()
    this.getPeminjam()
 },
    
  
    }
    </script>
    
<style scoped>
.backblue {
      background-color: aqua;
      color: aqua;
    
    }

.background{
  background-image: url(../assets/background.png);
  background-size: cover;
}

.bgnone {
  background-color: transparent;
}

.carduser {
  margin-left: 100px;
      margin-right: 100px; 
      height: max-content;
      width: 100%;
      margin: 50px; 
      padding: 50px;
}

.bgblue {
  background: rgba(86, 207, 255, 0.62);
  border-radius: 15px;
}

.fonthead{
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  color: #34758E;
}

li {
  display: inline;
  padding: 20px;
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #34758E;

}
</style>