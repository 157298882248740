import Vue from 'vue'
import Router from 'vue-router'
import Onboard from '../views/Onboard.vue'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Register from '../views/Register.vue'
import Profile from '../views/Profile.vue'
import Input from '../views/Input.vue'
import Detail from '../views/Detail.vue'
import Users from '../views/Users.vue'
import Peminjaman from '../views/Peminjaman.vue'
import PDF from '../views/EditPdf.vue'
import Onboard2 from '../views/Onboard2.vue'
import Task from '../views/Task.vue'


Vue.use(Router)


export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path : '/',
            name:'Onboard',
            component: Onboard
        },
        {
            path : '/home',
            name:'Home',
            component: Home
        },
        {
            path : '/login',
            name:'login-page',
            component: Login
        },
        {
            path : '/register',
            name:'register-page',
            component: Register
        },
        {
            path : '/profile',
            name:'Profile',
            component: Profile
        },
        {
            path : '/input',
            name:'input',
            component: Input
        },
        {
            path : '/users',
            name:'Users',
            component: Users
        },
        {
            path : '/peminjaman',
            name:'peminjaman',
            component: Peminjaman
        },
        {
            path : '/detail/:id',
            name:'Detail',
            component: Detail,
            props: true
        },
        {
            path : '/pdf/:id',
            name:'edit-pdf',
            component: PDF,
            props: true
        },
        {
            path : '/ob2',
            name:'onboard2',
            component: Onboard2
        },
        {
            path : '/task',
            name:'task-page',
            component: Task
        },
   
    ]
})
