<template>
<div class="body background fill-height">

    <v-container class="d-flex justify-start my-auto">

      <v-row>

        <div class="my-auto ms-0 ">
          <v-img max-height="100" max-width="80" src="../assets/bjs logo png 1.png"></v-img>
        </div>
        <div class="ms-0 my-auto">
        <v-img max-height="100" max-width="200" src="../assets/logobjsbaru.png"></v-img>
        </div>
        

        <div class="ms-auto">
          <v-btn
            @click="logout"
            style="margin-top: 50px; font-size: 20px;padding:16px;"
            depressed
            color="primary"
          >
            Logout
          </v-btn>
        </div>

      </v-row>

     

    </v-container>

    <v-container
      class="d-flex justify-center mb-6"
      flat
      tile
    >
    
    <v-row class="mt-15 mx-auto justify-center mb-6">

    <v-card
    v-if="isSpv || isAdmin || isBod" 
    @click="goTask"
    class="my-12 card"
    max-width="374"
  >
 
    <v-img
      height="250"
      max-width="250"
      src="../assets/task.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>KEGIATAN</p></v-card-title>

  </v-card>

    <v-card
    v-if="isAdmin || isSpv || isPeminjam"
    @click="document"
    class="my-12 card"
    max-width="374"
  >
 
    <v-img
      height="250"
      max-width="250"
      src="../assets/document2.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>DOCUMENT</p></v-card-title>

  </v-card>

  <v-card
  v-if="isAdmin || isSpv || isPeminjam || isBod"
  @click="profile"
    class=" my-12 card"
    max-width="374"
  >


    <v-img
      height="250"
      max-width="250"
      src="../assets/profile2.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>PROFILE</p></v-card-title>

  </v-card>

  <v-card
   v-if="isAdmin || isSpv"
  @click="users"
    class=" my-12 card"
    max-width="374"
  >


    <v-img
      height="250"
      max-width="250"
      src="../assets/user2.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>USERS</p></v-card-title>

  </v-card>

  <v-card
   v-if="isAdmin"
  @click="input"
    class=" my-12 card"
    max-width="374"
  >


    <v-img
      height="250"
      max-width="250"
      src="../assets/input2.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>INPUT</p></v-card-title>

  </v-card>

  <v-card
   v-if ="isAdmin || isPeminjam"
  @click="peminjaman"
    class=" my-12 card"
    max-width="374"
  >


    <v-img
      height="250"
      max-width="250"
      src="../assets/peminjaman2.png"
    ></v-img>

    <v-card-title class="d-flex justify-center mt-10"><p>PEMINJAMAN</p></v-card-title>

  </v-card>

  </v-row>
  </v-container>

</div>
</template>

<script>
// import axios from 'axios';
import Swal from 'sweetalert2'
// import swal from 'sweetalert'

export default {
  name: 'onboard2-page',
  data () {
      return {
        isAdmin : false,
        isSpv : false,
        isPeminjam : false,
        isBod: false

      }},
  methods: {
    document() {
      this.$router.push('/home');
    },
    profile() {
      this.$router.push('/profile');
    },
    users() {
      this.$router.push('/users');
    },
    input() {
      this.$router.push('/input');
    },
    peminjaman() {
      this.$router.push('/peminjaman');
    },
    goHome() {
      this.$router.push('/ob2');
      
    },
    goTask(){
      this.$router.push('/task');
    },
    logout(){
      Swal.fire({
          title: "<h3 style='font-family:cambria;'>Terima Kasih Telah Menggunakan BJS SIAP</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })

    }
  },
  
   created() {

    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }

    var role = localStorage.getItem("userRole")
    var istask = localStorage.getItem("istask")

    if(role == "admin"){
        this.isAdmin = true
        this.items = this.itemsAdmin
  

        if(istask == null){
          Swal.fire({
          icon: 'warning',
          title: "<h3 style='font-family:cambria;'>Jangan Lupa Isi Kegiatan Hari Ini</h3>",
        })
        }
        localStorage.setItem("istask", true);
      }

      if(role == "supervisor"){
        this.isSpv = true
        this.items = this.itemsSpv
  
        if(istask == null){
          Swal.fire({
          icon: 'warning',
          title: "<h3 style='font-family:cambria;'>Jangan Lupa Isi Kegiatan Hari Ini</h3>",
        })
        }

        localStorage.setItem("istask", true);
      }

      if(role == "peminjam"){
        this.isPeminjam = true
        this.items = this.itemsPeminjam
      }

      if(role == "bod"){
        this.isBod = true
      }

  }

}
</script>

<style scoped>
.body {
    background-image: url(../assets/bgonboard.webp);
    background-repeat: repeat-y ;
    background-size:  cover;
    padding-top: 40px;
    font-family: gemunuLibre !important;
  
}
.background {
  background-image: url(../assets/background.png);
  background-size: cover;

}

.swalTitle{
  font-family: gemunuLibre;
}

.card {
  margin: 20px;
  padding: 15px;
  /* background: linear-gradient(180deg, rgba(86, 207, 255, 0.7) 0%, rgba(35, 48, 46, 0) 100%); */
  background: rgba(86, 207, 255, 0.7);
  border-radius: 15px;
}

p{
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #262fa1;
}

h1{
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  color: #34758E;
}

</style>