<template>

<div class="d-flex background fill-height">
  
  <v-navigation-drawer
      style="background-color: #039BE5;min-width: 250px;height: auto; min-height: 790px; text-align: left;"
        class=" accent-4"
        dark
        permanent
      >
     
    
        <v-list>
          <div class="pa-2">
          
            <v-btn v-on:click="menu" block>
              Menu
            </v-btn>
          </div>
          
          <v-list-item
            style="color: white;"
            v-for="item in items"
            :key="item.title"
            link
            v-on:click="navigate(item.route)"
          >
         
        <v-list-item-icon>
          <v-icon  >{{ item.icon }}</v-icon>
        </v-list-item-icon>
  
        <v-list-item-content>
          <v-list-item-title  >{{ item.title }}</v-list-item-title>
        </v-list-item-content>
        
          </v-list-item>
        </v-list>


        <template v-slot:append>
          <div class="pa-2">
            <v-btn block>
              {{ moment().format('ddd, D MMMM YYYY') }}
            </v-btn>
          </div>
          
          <div class="px-2">
            <v-btn block>
              {{ moment().format('LT') }}
            </v-btn>
          </div>

          <div class="pa-2">
            <v-btn v-on:click="logout"  block>
              Logout
            </v-btn>
          </div>
        </template>
      </v-navigation-drawer>


           <v-container style="margin: 50px; padding: 0px;">
            

      <v-card class="bgblue" style="padding: 50px;">
        <v-container class="d-flex mx-0 mb-3">
          <div class="my-auto ms-0 ">
            <v-img max-height="100" max-width="80" src="../assets/bjs logo png 1.png"></v-img>
          </div>
          <div class="ms-0 my-auto">
            <v-img max-height="100" max-width="200" src="../assets/logobjsbaru.png"></v-img>
          </div>
        </v-container>

       <v-container>

          <div class="d-flex">

            <div class="my-auto">
              <v-img
              lazy-src="../assets/usertp.png"
              max-height="500"
              max-width="300"
              :src="avatar"
          ></v-img>
          <br>

          <label class="label"> 
              Change Photo
            <input accept="image/*"  type="file" id="file" ref="file" v-on:change="handleFileUpload()"/>
          </label>


            </div>
        

           <div style="padding: 20px; text-align: left;" >
            <h2>Name</h2>
            <p>{{ nama }}</p>

            <h2>Username</h2>
            <p>{{ username }}</p>

            <h2>Email</h2>
            <p>{{ email }}</p>

          
           </div>

           <div style="padding: 20px; text-align: left;">

            <h2>Role</h2>
            <p>{{ role }}</p>

            <h2>Password</h2>
            <p>*******</p>

<v-row >
<v-dialog
  v-model="dialog"
  persistent
  max-width="600px"
>
  <template v-slot:activator="{ on, attrs }">
    <v-col cols="3" class="my-auto">
    <v-btn
      color="primary"
      dark
      v-bind="attrs"
      v-on="on"
    >
      Edit
    </v-btn>
    </v-col>
  </template>
  <v-card>
    <v-card-title>
      <span class="text-h5">User Profile</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            cols="12"
       
          >
            <v-text-field
              v-model="nama"
              label="Legal Name"
              required
            ></v-text-field>
          </v-col>
       
          <v-col cols="12">
            <v-text-field
              v-model="username"
              label="Username"
              required
            ></v-text-field>
          </v-col>
     
          <v-col cols="12">
            <v-text-field
            v-model="email"
              label="Email*"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
            v-model="password"
              label="Password*"
              type="password"
              required
            ></v-text-field>
          </v-col>
      
      
        </v-row>
      </v-container>
     
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="dialog = false"
      >
        Close
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="updateProfile"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
  </v-row>

           </div>

          </div>

       </v-container>
    </v-card>

    
  </v-container>
   

</div>
</template>
    
<script>
    import axios from 'axios';
    import Swal from 'sweetalert2'
    import moment from 'moment'

    export default {
      name: 'profile-page',
      data () {
          return {
            moment:moment,
            isAdmin:false,
            isSpv: false,
            isPeminjam:false,
            dialog: false,
            search: '',  
            nama:"",
            username:"",
            email: "",
            file:"",
            role:"",
            password:"",
            avatar:"",
            items: [],
            itemsAdmin: [
              { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
              { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
              { title: 'Users', icon: 'mdi-account',route: '/users'  },
              { title: 'Input', icon: 'mdi-gavel',route: '/input'  },
              { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' },
              { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
            ],
            itemsSpv: [
              { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
              { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
              { title: 'Users', icon: 'mdi-account',route: '/users'  },
              { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
            ],
            itemsPeminjam: [
              { title: 'Document', icon: 'mdi-view-dashboard', route: '/home' },
              { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
              { title: 'Peminjaman', icon: 'mdi-clipboard-edit', route: '/peminjaman' }
            ],
            itemsBod: [
              { title: 'Profile', icon: 'mdi-account-box',route: '/profile'  },
              { title: 'Kegiatan', icon: 'mdi-clipboard-list-outline', route: '/task' }
            ],
          }
      },
      methods : {
        navigate: function (link) {
      this.$router.push(link);
    }   ,
      getData(){

       var userid = localStorage.getItem("userid");

       axios.get('https://jamkrindo.herokuapp.com/profile/' + userid).then(response => {
        console.log(response)
        let item = response.data.data[0]
        this.nama =  item.name
        this.username = item.username
        this.email = item.email
        this.role = item.role
        this.password = item.password
        this.avatar = item.avatar
        

        }).catch(function(error) {
            console.log(error)

        })
       }  ,
       uploadImage(){

            let formData = new FormData();

            formData.append('file', this.file);
        let a = this

            axios.post( 'https://jamkrindo.herokuapp.com/upload',
                formData,
                {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              }
            ).then(function(response){
           a.avatar = response.data.data.secure_url 
           a.updateProfile()
  
        })
        .catch(function(error){
          a.dialog = false
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  error
          })
        });


       },
       handleFileUpload(){
        this.file = this.$refs.file.files[0];
        this.uploadImage()
      },
       updateProfile(){


        this.dialog = false
       var userid = localStorage.getItem("userid");


       let a = this

            axios.put('https://jamkrindo.herokuapp.com/profile', {
              
              id:userid,
               name : a.nama,
               email : a.email,
               username : a.username,
               role : a.role,
               avatar : a.avatar,
               password : a.password
             

            }).then(response => {
          
            Swal.fire({
            icon: 'success',
            text:  "data diperbarui"
          })
          console.log(response)
            
            a.getData()
            

            }).catch(function(error) {
                console.log(error)
                Swal.fire({
            icon: 'error',
            text:  error
          })

            })

       },
        logout() {
          Swal.fire({
          title: "<h3 style='font-family:cambria;'>Terima Kasih Telah Menggunakan BJS SIAP</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })
        },
        menu() {
          this.$router.push("/ob2");
        }
      },
      created(){
        var userid = localStorage.getItem("userid")
        if(userid == null){
          this.$router.push("/");
        }
        var role = localStorage.getItem("userRole")

        if(role == "admin"){
          this.isAdmin = true
          this.items = this.itemsAdmin
        }

        if(role == "supervisor"){
          this.isSpv = true
          this.items = this.itemsSpv
        }

        if(role == "peminjam"){
          this.isPeminjam = true
          this.items = this.itemsPeminjam
        }

        if(role == "bod"){
          this.items = this.itemsBod
      }

        this.getData()
      }
      
    
    }
    </script>
    
<style scoped>
.backblue {
      background-color: aqua;
      color: aqua;
    
    }

.background {
  background-image: url(../assets/background.png);
  background-size: cover;
}
.label {
  background-color: #039BE5;
  font-size: 14px;padding: 8px;
  border-radius: 5px;
  font-weight: bold;
  color: white; 
  cursor:pointer;
}
.bgblue{
  background: rgba(86, 207, 255, 0.7);
  border-radius: 15px;
}

h2{
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
}

li {
  display: inline;
  padding: 20px;
  font-family: gemunuLibre;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #34758E;

}
</style>